<template>
  <div class="position-fixed fixed-bottom bg-primary border-lg shadow-lg footer-bottom">
    <div fluid class="container m-3 fixed-bottom">
      <div class="row d-flex justify-content-between align-items-end">
        <div class="col-4 col-sm-3 col-md-2 col-lg-2">
          <a href="https://cscdigital.com" class="text-white" target="_blank">
            <img class="img-fluid" :src="require('@/assets/img/logo_blanco_netvideo.png')" />
          </a>
        </div>
        <div class="col-4 col-sm-3 col-md-2 col-lg-2 text-center">
          <a @click="emitter.emit('goHome')" class="text-white text-center" target="_blank">
            <i class="bi bi-house-door-fill fs-6 text-warning"></i>
          </a>
        </div>
        <div class="col-3 col-sm-2 col-md-1 col-lg-1 align-content-end h-100">
          <!-- <a href="https://cscdigital.com" class="text-white" target="_blank">
              <img :src="require('@/assets/img/cad-vertical.png')" class="img-thumbnail" alt="cad">
          </a> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>


export default {
    name: 'Footer',
    props: {
        footerContent: {
            type: String,
            default: null
        },
    },
    mounted() {
        
    },
}
</script>

<style scoped lang="scss">
@import '~@/styles/app';

.footer {
	
}


</style>