import { createApp } from 'vue'
//import "bootstrap/dist/css/bootstrap.css"
import "bootstrap"
import "bootstrap-icons/font/bootstrap-icons.css"
import App from './App.vue'
import router from './router'
import store from './store'
import PusherPlugin from './plugins/pusher'
import mitt from 'mitt'
import {LoadingPlugin} from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';

import i18n from './i18n.ts'
import vLinkify from 'v-linkify';

const emitter = mitt()

const apiKey = 'ae458246b12fb963bcc4' // Pusher API apiKey

const app = createApp(App)
	.use(store)
	.use(router)
  .use(i18n)
	.use(PusherPlugin, { apiKey, cluster: 'mt1' })
	;

app.directive('linkified', vLinkify);

app.use(LoadingPlugin, {
  canCancel: false,
  color: '#FFFFFF',
  backgroundColor: '#000555',
  loader: 'dots',
  width: 100,
  height: 100,
  enforceFocus: true,
  opacity: 0.9,
  zIndex: 99999
})

app.config.globalProperties.emitter = emitter

app.mount('#app')

