<template>
  <div class="container p-5">
		<div class="row d-flex justify-content-center">
			<div class="col-6 col-md-4 col-lg-3">
				<img class="img-fluid" :src="require('@/assets/img/logo_blanco_netvideo.png')"/>
			</div>
			<div class="col-6 col-md-4 col-lg-3">
				<img class="img-fluid" :src="require('@/assets/img/mi-constancia.png')"/>
			</div>
			<div class="col-6 col-md-4 col-lg-3">
				<img class="img-fluid" :src="require('@/assets/img/eventappwhite.png')"/>
			</div>
			<div class="col-6 col-md-4 col-lg-3">
				<img class="img-fluid" :src="require('@/assets/img/pagos-vip-white.png')"/>
			</div>
			<div class="col-6 col-md-4 col-lg-3">
				<img class="img-fluid" :src="require('@/assets/img/registro-white.png')"/>
			</div>
		</div>
	</div>
</template>

<script>

export default {
    name: 'AppData',
	computed: {
	},
}
</script>

<style scoped lang="scss">
@import '~@/styles/app';


</style>