<template>
  <!-- No fade transition -->
  <div 
    class="modal" 
    id="msgModalRef" 
    ref="msgModalRef"
    data-bs-backdrop="static" 
    data-bs-keyboard="false" 
    tabindex="-1"
  > 
    <div class="modal-dialog modal-dialog-centered border border-0"> 
        <div class="modal-content bg-dark"> 
            <div class="modal-header border-0"> 
                <h5 class="modal-title text-white"> 
                  {{this.msg_data.header}} 
                </h5> 
            </div> 
            <div class="modal-body text-center text-white border-0"> 
              <div v-html="msg_data.message"></div>
            </div> 
            <div class="modal-footer border-0"> 
                <button 
                  type="button" 
                  class="btn btn-success"
                  @click="hiddenModal()"
                > 
                  {{msg_data.lblBtnClose}}
                </button>
            </div> 
        </div> 
    </div> 
  </div> 
</template>

<script>
import { Modal } from 'bootstrap';

export default {
  name: 'MsgBox',
  props: {
    msg_data: { 
      type: Object, default: () => ({
        header: {type: String, default: "I am modal vertically centered"},
        message: {type: String, default: "Card title"},
        lblBtnClose: {type: String, default: "Cerrar"},
      })
    }
  },
  data() {
    return {
      msgModal: null
    }
  },
  methods: {
    hiddenModal() {
      this.msgModal.dispose()
      this.$emit('closed')
    },
  },
  mounted () {
    this.msgModal = new Modal(this.$refs.msgModalRef)
    this.msgModal.show()
    //window.addEventListener("hidden.bs.modal", this.hiddenModal);

  },
}

</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '~@/styles/app';




</style>
