import { createStore } from 'vuex'
import axios from 'axios'

axios.defaults.headers.common['x-api-key'] = process.env.VUE_APP_API_KEY
axios.defaults.headers.common['x-custom-header'] = 'Hr+1cf2cXwL6ZuV5jmseao04OqU/OgmY'
if(process.env.NODE_ENV === 'development') axios.defaults.headers.common['ngrok-skip-browser-warning'] = '69420'

export default createStore({
  state: {
    title:{ type: String, default: null },
    landscape: false,
    device_size: null,
    status: '',
    token: null,
    url_to: null,
    url: process.env.VUE_APP_API_URL,
    event: {
      finished: null,
      broadcasting: null,
      url_to: null,
      survey_enabled: null,
      survey: null,
      demo_mode: null,
      modules: Array()
    },
    assistant: {
      name: null,
      email: null,
      id: null,
      key_id: null,
      remaining_attempts: null,
      exam_approved: false,
      answered_survey: false,
      session_id: null
    },
    event_key_id: null,
    data_rating: [],
    data_poll: [],
    data_word_cloud:[],
    watching_broadcast: false,
    already_answered: false,
    show_nav_bar: true,
    room: null,
    location: null
  },
  getters: {
    location: (state) => { return state.location },
    device_size: (state) => { return state.device_size },
    landscape: (state) => { return state.landscape },
    isLoggedIn: state => !!state.token,
    assistant: state => state.assistant,
    event_key_id: state => state.event_key_id,
    event: state => state.event,
    watching_broadcast: (state) => { return state.watching_broadcast },
    getModule: (state) => (short_name) => { 
      if(!short_name) return null;
      let module = state.event.modules.find((item) => item.short_name == short_name);
      if(module) return module;
      return null;
    },
    isModuleActive: (state) => (short_name) => { 
      if(!short_name) return false;
      let module = state.event.modules.find((item) => item.short_name == short_name);
      if(module && module.active === 1) return true;
      return false;
    },
    already_answered: (state) => {
      if(state.assistant.answered_survey || state.already_answered) return true
      else return false
    },
    show_nav_bar: (state) => { return state.show_nav_bar },
    data_rating: (state) => { return state.data_rating },
    data_poll: (state) => { return state.data_poll },
    data_word_cloud: (state) => { return state.data_word_cloud },
    room: (state) => { return state.room }
  },
  mutations: {
    set_location(state, location) {
      state.location = location
    }, 
    set_event_key_id(state, event_key_id) {
      state.event_key_id = event_key_id
    },
    set_data_rating(state, data_rating) {
      state.data_rating = data_rating
    },
    set_data_poll(state, data_poll) {
      state.data_poll = data_poll
    },
    set_data_word_cloud(state, data_word_cloud) {
      state.data_word_cloud = data_word_cloud
    },
    set_url_to(state, url_to) {
      state.url_to = url_to
    },
    set_landscape(state, landscape) {
      state.landscape = landscape
    },
    auth_success(state, item) {
      if(item.remember_me == true) {
        localStorage.setItem('u', item.key_id)
      }
      state.status = 'success'
      state.token = item.key_id
      state.assistant = {
        name: item.full_name,
        email: item.email,
        nickname: item.nickname,
        id: item.id,
        key_id: item.key_id,
        remaining_attempts: item.remaining_attempts,
        exam_approved: item.exam_approved,
        answered_survey: item.answered_survey,
        session_id: item.session_id,
        join_url: item.join_url,
      }

      state.data_poll = item.poll_data
      state.data_rating = item.pending_ratings

      state.data_word_cloud = item.active_word_clouds
      state.data_word_cloud.forEach(element => {
        if(element.json_options) element.json_options = JSON.parse(element.json_options)
      });
              
    },
    logout(state) {
      localStorage.removeItem('u')
      //localStorage.removeItem('m')
      state.token = null
      state.assistant = {
        name: null,
        email: null,
        id: null,
        key_id: null,
        remaining_attempts: null,
        exam_approved: false,
        answered_survey: false,
        session_id: null
      }
    },
    set_title(state, title) {
      state.title = title
    },
    set_url(state, url) {
      state.url = url
    },
    set_device_size(state, device_size) {
      state.device_size = device_size
    },
    set_assistant(state, assistant) {
      state.assistant = assistant
    },
    set_event(state, event) {
      state.event = event
    },
    set_watching_broadcast(state, watching_broadcast) {
      state.watching_broadcast = watching_broadcast
    },
    set_already_answered(state, already_answered) {
      state.already_answered = already_answered
    },
    set_show_nav_bar(state, show_nav_bar) {
      state.show_nav_bar = show_nav_bar
    },
    set_room(state, room) {
      state.room = room
    }
  },
  actions: {
    post({ commit }, api) {
      return new Promise((resolve, reject) => {
        axios({ url: this.state.url + api.url, data: api.data, method: 'POST', headers: api.headers, params: api.params })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            if(err && err.response) reject(err.response.data)
            else reject(null)
          })
      })
    },
    get({ commit }, api) {
      return new Promise((resolve, reject) => {
        axios({ url: this.state.url + api.url, params: api.params, method: 'GET' })
          .then(resp => {
            resolve(resp)
          }).catch(err => {
            if(err.response.statusText=='Unauthorized') {
              alert('Su sesión ha Expirado')
              sessionStorage.removeItem('token')
              delete axios.defaults.headers.common['Authorization']
              resolve(err.response)
            }
            reject(err.response.data)
          })
      })
    },
    get_static({ commit }, static_file) {
      return new Promise((resolve, reject) => {
        axios({ url: process.env.VUE_APP_PUBLIC_PATH+'static/' + static_file, method: 'GET' })
          .then(resp => {
            resolve(resp)
          }).catch(err => {
            reject(err.response.data)
          })
      })
    },
    download({ commit }, api) {
      return new Promise((resolve, reject) => {
        axios({ url: this.state.url + api.url, data: api.data, method: api.method, headers: api.headers, responseType: 'blob', params: api.params })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err.response.data)
          })
      })
    },
    download_by_url({ commit }, param) {
      return new Promise((resolve, reject) => {
        axios({ url: param.url, responseType: 'blob' })
          .then(resp => {
            resolve(resp)
          })
          .catch(err => {
            reject(err.response)
          })
      })
    },
  },
  modules: {
  }
})
